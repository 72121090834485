<template>
  <b-container fluid>
    <b-skeleton-wrapper :loading="NewestProductsLoading">
      <template #loading>
        <b-container fluid>
          <landingLoader></landingLoader>
        </b-container>
      </template>
      <div>
        <div class="bg-white my-4 ">
          <b-row align-v="center" class="py-2 px-0 flickity-card mx-1">
            <b-col align="start" class="flickity-title">
              <h6 v-if="$mq === 'sm'">
                {{ $t("mainCat") }}
              </h6>
              <h4 v-else>
                {{ $t("mainCat") }}
              </h4>
            </b-col>
            <b-col align="end">
              <b-button
                variant="outline-secondary"
                class="py-1 font-weight-bold"
                :style="[$mq == 'sm' ? 'font-size: 12px;' : 'font-size: 15px;']"
                :to="{ name: 'AllCat', params: { param: 'All' } }"
                >{{ $t("ShowMore") }}</b-button
              >
            </b-col>
          </b-row>

          <flickity
           
            :options="$mq == 'sm' ? flickityOptionsCategories : flickityOptionsCategoriesDesk"
            ref="flickity"
            v-if="Categories.length != 0"
            class="flickityCategories"
          >
            <CatSlideItem
              v-for="Category in Categories"
              v-bind:key="Category.id"
              v-bind:Category="Category"
              class="carousel-cell mx-0 col-md-2 col-sm-3 col-3"
            ></CatSlideItem>
          </flickity>
        </div>
        <mq-layout :mq="['sm']">
          <a
            target="_blank"
            href="https://wa.me/971565628959?text= مرحبا, أريد الاستفسار عن منتجات الريبان "
            class=""
          >
            <img
              class="w-100 shadow rounded py-2"
              style="height: 150px"
              :src="require(`../../assets/banners/reban.jpg`)"
            />
          </a>
        </mq-layout>
        <div v-if="!NewestProductsLoading" class="bg-white my-4 slide-h">
          <b-row align-v="center" class="pt-2 px-0 flickity-card m-1">
            <b-col align="start" class="flickity-title">
              <h6 v-if="$mq === 'sm'">
                {{ $t("newProd") }}
              </h6>
              <h4 v-else>
                {{ $t("newProd") }}
              </h4>
            </b-col>
            <b-col align="end">
              <b-button
                variant="outline-secondary"
                class="py-1 font-weight-bold"
                :style="[$mq == 'sm' ? 'font-size: 12px;' : 'font-size: 15px;']"
                :to="{ name: 'newest', params: { param: 'All' } }"
                >{{ $t("ShowMore") }}</b-button
              >
            </b-col>
          </b-row>
          <flickity :options="flickityOptions2" v-if="$mq != 'sm'">
            <ProdCard
              v-for="index in 10"
              v-bind:key="index"
              v-bind:Product="NewestProducts.newest.items[index - 1]"
              class="col-sm-2 col-8 px-1"
            ></ProdCard>
          </flickity>

          <flickity :options="flickityOptions" v-if="$mq == 'sm'">
            <ProdCard
              v-for="index in 10"
              v-bind:key="index"
              v-bind:Product="NewestProducts.newest.items[index - 1]"
              class="col-sm-2 col-8 px-1"
            ></ProdCard>
          </flickity>
        </div>
      </div>
    </b-skeleton-wrapper>

    <!-- <div class="container-fluid">
      <Flickity class="d-sm-none d-block mobBannerSlier " ref="flickity" :options="flickityOptions" >  
        <div class="BannerSlideItem col-10 " >
        <router-link :to="{name:'ProdByCat' , params:{ProdByCat:'مزهريات'}}" >
          <img  class="mobileBanner " src="@/assets/banners/7.jpg" alt="banner">
        </router-link>
        </div>            
        <div class="BannerSlideItem col-10 ">
          <router-link :to="{name:'ProdByCat' , params:{ProdByCat:'الوطني%20السعودي'}}">
            <img  class="mobileBanner " src="@/assets/banners/2.jpeg" alt="banner">
          </router-link>
        </div>
          <div class="BannerSlideItem col-10 ">
            <router-link :to="{name:'ProdByTag' , params:{ProdByTag:'baby_box_group'}}">
              <img  class="mobileBanner " src="@/assets/banners/1.jpeg" alt="banner">

            </router-link>
        </div>
        <div class="BannerSlideItem  col-10 ">
          <router-link :to="{name:'ProdByCat' , params:{ProdByCat:'تخرج'}}" >
            <img  class="mobileBanner " src="@/assets/banners/0.jpeg" alt="banner">
          </router-link>
         
        </div>
        
        <div class="BannerSlideItem col-10 ">
          <router-link :to="{name:'ProdByCat' , params:{ProdByCat:'المواليد'}}">
            <img  class="mobileBanner " src="@/assets/banners/3.jpeg" alt="banner">
          </router-link>

        </div>
        <div class="BannerSlideItem col-10 ">
          <router-link :to="{name:'ProdByCat' , params:{ProdByCat:'أعياد%20الميلاد'}}">
            <img  class="mobileBanner " src="@/assets/banners/4.jpeg" alt="banner">
          </router-link>
        </div>
        <div class="BannerSlideItem col-10 ">
          <router-link :to="{name:'ProdByCat' , params:{ProdByCat:'الأعراس'}}">
            <img  class="mobileBanner " src="@/assets/banners/5.jpeg" alt="banner">
          </router-link>
        </div>
      </Flickity>
    </div> -->
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CatSlideItem from "../items/CatSlideItemCircle.vue";
import Flickity from "vue-flickity";
// import BannerSliderItem from '../items/BannerSlideItem.vue';
import landingLoader from "../widgets/landingLoader.vue";
import ProdCard from "../items/ProdCard.vue";

export default {
  name: "CategorySilde",
  components: {
    CatSlideItem,
    Flickity,
    ProdCard,
    // BannerSliderItem
    landingLoader,
  },
  methods: {
    ...mapActions(["getNewest"]),
    getResults() {
      var obj = {
        breakpoint: this.$mq,
        page: 1,
      };

      this.getNewest(obj);
    },
  },
  computed: {
    ...mapGetters(["Categories", "NewestProducts"]),
  },
  data() {
    return {
      flickityOptions: {
        rightToLeft: true,
        initialIndex: 2,
        pageDots: false,
        freeScroll: false,
        wrapAround: false,
        contain: true,
        prevNextButtons: false,
        imagesLoaded: true,
      },
      flickityOptions2: {
        rightToLeft: true,
        initialIndex: 2,
        pageDots: false,
        freeScroll: false,
        wrapAround: false,
        contain: true,
        prevNextButtons: true,
        imagesLoaded: true,
      },
      flickityOptionsCategories: {
        rightToLeft: true,
        initialIndex: 2,
        pageDots: false,
        freeScroll: true,
        wrapAround: true,
        contain: false,
        prevNextButtons: false,
        imagesLoaded: true,
      },
      flickityOptionsCategoriesDesk: {
        rightToLeft: true,
        initialIndex: 2,
        pageDots: false,
        freeScroll: true,
        wrapAround: true,
        contain: false,
        prevNextButtons: true,
        imagesLoaded: true,
      },
      NewestProductsLoading: true,

      display: false,
    };
  },
  mounted() {
    this.getResults();
    setTimeout(() => {
      this.display = true;
    }, 4000);
  },
  watch: {
    NewestProducts(newValue) {
      if (newValue.newest) {
        this.NewestProductsLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.flickity-card {
  background-color: #ffffff !important;
}
.see-more {
  border: #92278f 1px solid;
  padding: 5px;
  font-weight: 500;
  text-decoration: none;
  border-radius: 5px;
}
.see-more:hover {
  color: #fff;
  background-color: #eb5b2c;
}
.flickity-title {
  font-weight: bold;
  font-size: 20px;
  color: #eb5b2c !important;
}
.mobBannerSlier {
  height: 126px !important;
}
.flickity-viewport {
  height: 101.359px !important;
}
.category-carousel {
  background-color: white;
  padding: 18px 0;
}
.BannerSlider {
  margin: 12px 0;
}
.BannerSlideItem {
  display: inline-block;
  padding: 0;
}
.BannerSlideItem img {
  width: 95%;
  height: auto;
}
.BannersSlide .carousel-inner .carousel-item img {
  padding: 16px;
}
.slide-h {
  min-height: 387px !important;
}
</style>
