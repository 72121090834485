<template>
  <div class="he">
    <div class="product-grid">
      <div class="product-image mx-1">
        <router-link
          :to="{
            name: 'ProdByCat',
            params: { ProdByCat: Category.id },
          }"
          style="min-width: 150"
        >
          <vue-load-image>
            <img
              slot="image"
              class="img-fluid mx-auto p-1 rounded-circle image"
              :src="
                Category.image.src
                  ? Category.image.src
                  : '@/assets/placeholder.png'
              "
              :alt="Category.name"
            
              width="100"
              height="100"
             />
            
            <img
              slot="preloader"
              class="img-fluid mx-auto"
              src="@/assets/loader.gif"
              width="100"
              height="100"
              :alt="Category.name"
             />
            <img
              slot="error"
              class="img-fluid mx-auto image"
              src="@/assets/placeholder.png"
              width="100"
              height="100"
              :alt="Category.name"
            />
          </vue-load-image>
        </router-link>
      </div>
      <div class="product-data">
        <h5
          class=""
          style="
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
         
            
            transition: all 0.4s ease-in-out;
          "
        >
          <router-link
          class="text-dark"
            :to="{
              name: 'ProdByCat',
              params: { ProdByCat: Category.id },
            }"
            >{{ Category.name }}</router-link
          >
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import VueLoadImage from "vue-load-image";

export default {
  name: "CatSideItem",
  components: {
    "vue-load-image": VueLoadImage,
  },

  props: ["Category"],

};
</script>

<style scoped>
.image {
  /* border: 2px solid #eb5b2c; */
  border: none;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin: 5px;
  /* box-shadow: 0px 1px 5px 1px #808080; */
  transition: box-shadow 0.3s ease-in-out;
  transition: all 0.3s ease;
}
.image:hover {
  box-shadow: 0 2px 10px #eb5b2c;
  transform: scale(0.9);
}

.Cat-Slide-item {
  display: inline-block;
  /* cursor: pointer; */
  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
  transition: all 0.3s ease;
  margin: 0px 10px;
}
.Cat-Slide-item:hover {
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
  margin: 0px 20px;
}

.card {
  border: none !important;
}

.product-grid {
  text-align: center;
  border-radius: 15px;
  /* border: 1px solid #e7e7e7; */
  overflow: hidden;
  /* transition: all 0.4s ease-out; */
  background: none;
  background-color: none;
  /* width: 70%; */
  height: auto;
}

.product-grid .product-image {
  position: relative;
}

.product-grid .product-image img {
  height: auto;
}

.product-grid .product-links li a:hover {
  color: #fff;
  background: #fe6a00;
  box-shadow: 1px 1px 30px 0 rgba(0, 0, 0, 0.2);
}
.product-grid .product-links li a:before,
.product-grid .product-links li a:after {
  content: attr(data-tip);
  color: #fff;
  background-color: #555;
  font-size: 12px;
  line-height: 18px;
  /* padding: 5px 10px; */
  white-space: nowrap;
  display: none;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: -40px;
  transition: all 0.3s ease 0s;
}
.product-grid .product-links li a:after {
  content: "";
  height: 10px;
  width: 10px;
  padding: 0;
  transform: translateX(-50%) rotate(45deg);
  top: -18px;
  z-index: -1;
}
.product-grid .product-links li a:hover:before,
.product-grid .product-links li a:hover:after {
  display: block;
}
  
@media screen and (max-width: 1200px) {
  .product-grid {
    margin: 0 0 2px;
  }
}
</style>