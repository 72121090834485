<template>
  <div class="home" style="background-color: #f5f5f5ec !important">
    <!-- <div class="cssmarquee">
      <p>
        عملائنا الأعزاء، نحيطكم علمآ بأننا نجري بعض التعديلات على الموقع في
        الوقت الحالي وسوف يصبح جاهزاً خلال بضعة أيام وشكرآ.
      </p>
    </div> -->

    <carouselWidget></carouselWidget>
    <CategorySlide></CategorySlide>
    <!-- <Ribban/> -->
    <ProdTag Tag="1031" :tagName="$t('PopularProducts')"></ProdTag>

    <mq-layout :mq="['md', 'lg']">
      <ProdBoxList></ProdBoxList>
    </mq-layout>

    <mq-layout :mq="['sm']">
      <GridTag Tag="756" tagName="مزهريات" count="9"></GridTag>
  
      <!-- <GridTag Tag="1033" tagName="مزهريات شفافة" count="9"></GridTag>
      <GridTag Tag="1034" tagName="مزهريات سيراميك" count="9"></GridTag>
      <GridTag Tag="1032" tagName="مزهريات ملونة" count="9"></GridTag> -->
    </mq-layout>
    <Slider Tag="1434" tagName="شرائط قابلة للطباعة"></Slider>

    <SpecialProduct></SpecialProduct>
    <mq-layout :mq="['md', 'lg']">
      <ProdCat Cat="865" catName="تغاليف الورد"></ProdCat>
      <ProdCat Cat="1071" catName="تغاليف الهدايا"></ProdCat>
    </mq-layout>

    <mq-layout :mq="['sm']">
      <ImageCat
        :src="require(`../assets/Flowers-Wrapping-paper.jpg`)"
        :label="`تغاليف الورد`"
        :cat="865"
      />

      <ImageCat
        :src="require(`../assets/Gifts-Wrapping-paper.jpg`)"
        :label="`تغاليف الهدايا`"
        :cat="1071"
      />

      <a
        target="_blank"
        href="https://wa.me/971565628959?text= مرحبا, أريد الاستفسار عن منتجات الاكياس "
        class=""
      >
        <img
          class="w-100 shadow rounded py-2 "
          style="height: 150px"
          :src="require(`../assets/banners/bags.webp`)"
        />
      </a>
    </mq-layout>
    <mq-layout :mq="['lg', 'md']">
      <TwoBanners></TwoBanners>
    </mq-layout>
    <mq-layout :mq="['lg', 'md']">
      <ProdTag Tag="963" tagName="منتجات البوكسات"></ProdTag>
    </mq-layout>
    <mq-layout :mq="['sm']">
      <GridTag Tag="963" tagName="منتجات البوكسات" count="4"></GridTag>
    </mq-layout>
    <mq-layout :mq="['md', 'lg']">
      <ProdCat Cat="850" catName="ورد دائم"></ProdCat>
      <ProdCat Cat="964" catName="ورد صناعي"></ProdCat>
    </mq-layout>

    <mq-layout :mq="['sm']">
      <ImageCat
        :src="require(`../assets/permanent-roses.jpg`)"
        :label="`ورد دائم`"
        :cat="850"/>
      <ImageCat
        :src="require(`../assets/artificial-flowers.jpg`)"
        :label="`ورد صناعي`"
        :cat="964"
    /></mq-layout>
    <!-- <mq-layout :mq="['sm']">
      <GridTag Tag="1039" tagName="بالون فويل" count="9"></GridTag>
    </mq-layout> -->
    <mq-layout :mq="['md', 'lg']">
      <ProdTag Tag="1464" tagName="أكياس"></ProdTag>
      <ProdCat Cat="1057" catName="أكسسوارات الورد"></ProdCat>
    </mq-layout>
    <!-- <mq-layout :mq="['md', 'lg']">
          <ProdBoxTag></ProdBoxTag>
        </mq-layout> -->
    <mq-layout :mq="['md', 'lg']">
      <homeList />
    </mq-layout>

    <UserRates class="my-5" :rates="rateArr" v-if="displayRateCar"></UserRates>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import ProdList from "../components/lists/ProdList.vue";
import CategorySlide from "../components/widgets/Category.vue";
import Slider from "../components/widgets/3dSlider.vue";
import ProdBoxList from "../components/lists/ProdBoxList.vue";
// import ProdBoxTag from "../components/lists/ProdBoxTag.vue";
import carouselWidget from "../components/widgets/Carousel.vue";
import homeList from "../components/lists/HomeList.vue";
import TwoBanners from "../components/widgets/TwoBanners.vue";
import ImageCat from "../components/widgets/ImageCat.vue";
import SpecialProduct from "../components/widgets/SpecialProduct.vue";
// import landingLoader from "../components/widgets/landingLoader.vue";
import UserRates from "../components/widgets/UserRates.vue";
import ProdTag from "../components/widgets/ProdTag.vue";
import GridTag from "../components/widgets/GridTag.vue";
import ProdCat from "../components/widgets/ProdCat.vue";
import axios from "axios";
// import Ribban from "../components/widgets/ribban.vue";
export default {
  name: "home",
  components: {
    carouselWidget,
    homeList,
    CategorySlide,
    ProdBoxList,
    GridTag,
    // ProdBoxTag,
    TwoBanners,
    SpecialProduct,
    // landingLoader,
    UserRates,
    ProdTag,
    ProdCat,
    Slider,
    // CatGridList,
    ImageCat,
    // Ribban
  },
  computed: {
    ...mapGetters(["LandingLayout"]),
  },
  methods: {
    ...mapActions(["getLanding"]),
  },
  data() {
    return {
      landingLoading: true,
      displayRateCar: false,
      rateArr: [],
      show: true,
    };
  },
  watch: {
    LandingLayout(newValue) {
      if (newValue.desktop) {
        this.landingLoading = false;
      }

      if (newValue.mobile) {
        this.landingLoading = false;
      }
    },
  },
  mounted() {
    var obj = {
      breakpoint: this.$mq,
    };
    this.getLanding(obj);

    var self = this;
    var url = process.env.VUE_APP_DEVBASEURL + "/getRate";
    axios.get(url).then(function(resp) {
      self.$data.rateArr = resp.data;

      self.$data.displayRateCar = true;
    });
    this.$gtag.event("Home Page", { method: "Google" });
  },
  metaInfo: {
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "تجارة كافة مستلزمات محلات الزهور والهدايا بالجملة. احصل على أفضل تجربة في التسوق عبر الإنترنت واكتشف أحدث منتجات مستلزمات الأزهار والهدايا مثل ورق التغليف والشرائط والبوكسات وا لكياس والورد وغيرها",
      },
    ],
  },
};
</script>

<style scoped>
.modal-header {
  border: none !important;
}

.modal-footer {
  border: none !important;
}
/* 
 
.cssmarquee {
  height: 50px;
  overflow: hidden;
  position: relative;
}

.cssmarquee p {
  font-size: 1em;
  color: #92278f;
  position: absolute;
  font-weight: bold;
  width: max-content;
  height: 100%;
  margin: 0;
  line-height: 50px;
  text-align: center;
  transform: translateX(100%);
  animation: cssmarquee 15s linear infinite;
}

@keyframes cssmarquee {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}
*/
</style>
