var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"he"},[_c('div',{staticClass:"product-grid"},[_c('div',{staticClass:"product-image mx-1"},[_c('router-link',{staticStyle:{"min-width":"150"},attrs:{"to":{
          name: 'ProdByCat',
          params: { ProdByCat: _vm.Category.id },
        }}},[_c('vue-load-image',[_c('img',{staticClass:"img-fluid mx-auto p-1 rounded-circle image",attrs:{"slot":"image","src":_vm.Category.image.src
                ? _vm.Category.image.src
                : '@/assets/placeholder.png',"alt":_vm.Category.name,"width":"100","height":"100"},slot:"image"}),_c('img',{staticClass:"img-fluid mx-auto",attrs:{"slot":"preloader","src":require("@/assets/loader.gif"),"width":"100","height":"100","alt":_vm.Category.name},slot:"preloader"}),_c('img',{staticClass:"img-fluid mx-auto image",attrs:{"slot":"error","src":require("@/assets/placeholder.png"),"width":"100","height":"100","alt":_vm.Category.name},slot:"error"})])],1)],1),_c('div',{staticClass:"product-data"},[_c('h5',{staticStyle:{"font-size":"14px","font-weight":"500","text-transform":"uppercase","transition":"all 0.4s ease-in-out"}},[_c('router-link',{staticClass:"text-dark",attrs:{"to":{
            name: 'ProdByCat',
            params: { ProdByCat: _vm.Category.id },
          }}},[_vm._v(_vm._s(_vm.Category.name))])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }