<template>
  <div class="bg-white py-1">
    <b-container fluid>
      <b-skeleton-wrapper :loading="ProdByTagLoading">
        <template #loading>
          <b-container fluid>
            <PordListLoader></PordListLoader>
          </b-container>
        </template>
        <div v-if="!ProdByTagLoading && products!=null" class="bg-white my-4">
          <b-row v-if="products.length>0" align-v="center" class="py-2 px-0 flickity-card mx-1">
            <b-col align="start" class="flickity-title">
              <h6 v-if="$mq === 'sm'">{{tagName}}</h6>
              <h4 v-else>{{tagName}}</h4>
            </b-col>
            <b-col align="end">
              <b-button variant="outline-secondary" class="py-1 font-weight-bold"
                :style="[$mq == 'sm' ? 'font-size: 12px;' : 'font-size: 15px;']"
                :to="{ name: 'ProdByTag', params: { ProdByTag:Tag } }">{{ $t("ShowMore") }}</b-button>



            </b-col>
          </b-row>

          <carousel-3d v-if="products.length>0" :autoplay="true" :border="0" :width="$mq === 'sm' || $mq === 'md' ? 200 : 450"
            :height="$mq === 'sm' || $mq === 'md' ? 200 : 450" class="col-12 col-sm-8">
            <slide v-for="(prod,index) in products.slice(0,8)" :key="index" :index="index" class="shadow">
        
              <router-link :to="{
                                                            name: 'ProdOne',
                                                            params: {
                  Id: prod.id
                },
              }">
                <img class="img-fluid" :src="
                  prod.images[0].src
                    ? prod.images[0].src
                    : '@/assets/placeholder.png'
                " :alt="prod.name" />
              </router-link>
            </slide>
          </carousel-3d>
        </div>
      </b-skeleton-wrapper>
    </b-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import PordListLoader from "../widgets/PordListLoader.vue";
import { Carousel3d, Slide } from "vue-carousel-3d";
import axios from "axios";
import cookie from 'vue-cookies';
export default {
  name: "Slider",
  props: ["Tag", "tagName"],
  components: {
    Carousel3d,
    Slide,
    PordListLoader,
  },
  methods: {
    ...mapActions(["getProdByTag"]),
    getResults() {
      var self = this;
      var CountryVal = cookie.get('shipCountry');
      var CurrVal = cookie.get('wmc_current_currency');
      let cur;
      let country

      if (CountryVal && CurrVal) {
        cur = CurrVal;
        country = CountryVal;
      } else {
        cur = 'SAR';
        country = 'SA';
      }
      var setUrl = process.env.VUE_APP_DEVBASEURL + '/ProdByTag/' + this.Tag + '/' + cur + '/' + country + '/' + this.$mq + '/' + "New" + '/' + "1000" + '/' + "0" + '/' + "5" + '/' + "0";

      axios.get(setUrl).then(function (response) {

        self.products = response.data.tag
      })
    },
  },
  computed: {

  },
  data() {
    return {
      ProdByTagLoading: true,
      products: []

    };
  },
  mounted() {
    this.getResults();

  },
  watch: {
    products(newValue) {
      if (newValue) {

        this.ProdByTagLoading = false;
      }
    },

  },
};
</script>

<style scoped>
.flickity-card {
  background-color: #ffffff !important;
}

.see-more {
  border: #92278f 1px solid;
  padding: 5px;
  font-weight: 500;
  text-decoration: none;
  border-radius: 5px;
}

.see-more:hover {
  color: #fff;
  background-color: #eb5b2c;
}

.flickity-title {
  font-weight: bold;
  font-size: 20px;
  color: #eb5b2c !important;
}

.mobBannerSlier {
  height: 126px !important;
}

.flickity-viewport {
  height: 101.359px !important;
}

.category-carousel {
  background-color: white;
  padding: 18px 0;
}

.BannerSlider {
  margin: 12px 0;
}

.BannerSlideItem {
  display: inline-block;
  padding: 0;
}

.BannerSlideItem img {
  width: 95%;
  height: auto;
}

.BannersSlide .carousel-inner .carousel-item img {
  padding: 16px;
}
</style>