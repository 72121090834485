var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white py-1"},[_c('b-container',{attrs:{"fluid":""}},[_c('b-skeleton-wrapper',{attrs:{"loading":_vm.ProdByTagLoading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('b-container',{attrs:{"fluid":""}},[_c('PordListLoader')],1)]},proxy:true}])},[(!_vm.ProdByTagLoading && _vm.products!=null)?_c('div',{staticClass:"bg-white my-4"},[(_vm.products.length>0)?_c('b-row',{staticClass:"py-2 px-0 flickity-card mx-1",attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"flickity-title",attrs:{"align":"start"}},[(_vm.$mq === 'sm')?_c('h6',[_vm._v(_vm._s(_vm.tagName))]):_c('h4',[_vm._v(_vm._s(_vm.tagName))])]),_c('b-col',{attrs:{"align":"end"}},[_c('b-button',{staticClass:"py-1 font-weight-bold",style:([_vm.$mq == 'sm' ? 'font-size: 12px;' : 'font-size: 15px;']),attrs:{"variant":"outline-secondary","to":{ name: 'ProdByTag', params: { ProdByTag:_vm.Tag } }}},[_vm._v(_vm._s(_vm.$t("ShowMore")))])],1)],1):_vm._e(),(_vm.products.length>0)?_c('carousel-3d',{staticClass:"col-12 col-sm-8",attrs:{"autoplay":true,"border":0,"width":_vm.$mq === 'sm' || _vm.$mq === 'md' ? 200 : 450,"height":_vm.$mq === 'sm' || _vm.$mq === 'md' ? 200 : 450}},_vm._l((_vm.products.slice(0,8)),function(prod,index){return _c('slide',{key:index,staticClass:"shadow",attrs:{"index":index}},[_c('router-link',{attrs:{"to":{
                                                          name: 'ProdOne',
                                                          params: {
                Id: prod.id
              },
            }}},[_c('img',{staticClass:"img-fluid",attrs:{"src":prod.images[0].src
                  ? prod.images[0].src
                  : '@/assets/placeholder.png',"alt":prod.name}})])],1)}),1):_vm._e()],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }