<template>
  <b-container fluid>
    <div class="bg-white my-4">
      <b-row align-v="center" class="pt-2 px-0 m-1">
        <b-col align="start" class="text-primary">
          <h6>
            {{ label }}
          </h6>
        </b-col>
        <b-col align="end">
          <b-button
            variant="outline-secondary"
            class="py-1 font-weight-bold"
            :style="[$mq == 'sm' ? 'font-size: 12px;' : 'font-size: 15px;']"
            :to="{ name: 'ProdByCat', params: { ProdByCat: cat } }"
            >{{ $t("ShowMore") }}</b-button
          >
        </b-col>
      </b-row>
      <router-link :to="{ name: 'ProdByCat', params: { ProdByCat: cat } }">
        <img class="w-100  rounded" style="height: 360px; " :src="src" />
      </router-link>
    </div>
  </b-container>
</template>

<script>
export default {
  props: ["src", "label", "cat"],
  name: "ImageCat",
};
</script>

<style></style>
