<template>
  <div class="homeList">
    <div class="container-fluid py-3">
      <div class="row">
        <div class="col-sm-6 col-12 homeListItem">
          <div
            href="https://alyaman.com/product/%d8%a7%d9%84%d9%8a%d9%88%d9%85-%d8%a7%d9%84%d9%88%d8%b7%d9%86%d9%8a-%d8%a7%d9%84%d8%b3%d8%b9%d9%88%d8%af%d9%8a-%d9%85%d8%ac%d9%85%d9%88%d8%b9%d8%a9-%d8%b2%d9%8a%d9%86%d8%a9-%d8%a3%d8%b3%d9%82%d9%81-15/"
            class="d-flex"
          >
            <img
              src="@/assets/logo/3.png"
              class="HomeListLogo img-fluid mx-auto"
              width="64"
              height="64"
              alt=""
            />
            <div class="mx-2 text-justify">
              <span class="headingH">{{ $t("Social") }}</span>
              <span class="content py-3">
                {{ $t("SocialDesc") }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-12 homeListItem">
          <div
            href="https://alyaman.com/product/%d8%a7%d9%84%d9%8a%d9%88%d9%85-%d8%a7%d9%84%d9%88%d8%b7%d9%86%d9%8a-%d8%a7%d9%84%d8%b3%d8%b9%d9%88%d8%af%d9%8a-%d9%85%d8%ac%d9%85%d9%88%d8%b9%d8%a9-%d8%b2%d9%8a%d9%86%d8%a9-%d8%a3%d8%b3%d9%82%d9%81-15/"
            class="d-flex"
          >
            <img
              src="@/assets/logo/1.png"
              class="HomeListLogo img-fluid mx-auto"
              width="64"
              height="64"
              alt=""
            />
            <div class="mx-2 text-justify">
              <span class="headingH">{{ $t("AirShip&SeaShip") }}</span>
              <span class="content py-3">
                {{ $t("AirShip&SeaShipDesc") }}
              </span>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-12 homeListItem">
          <div
            href="https://alyaman.com/product/%d8%a7%d9%84%d9%8a%d9%88%d9%85-%d8%a7%d9%84%d9%88%d8%b7%d9%86%d9%8a-%d8%a7%d9%84%d8%b3%d8%b9%d9%88%d8%af%d9%8a-%d9%85%d8%ac%d9%85%d9%88%d8%b9%d8%a9-%d8%b2%d9%8a%d9%86%d8%a9-%d8%a3%d8%b3%d9%82%d9%81-15/"
            class="d-flex"
          >
            <img
              src="@/assets/logo/2.png"
              class="HomeListLogo img-fluid mx-auto"
              width="64"
              height="64"
              alt=""
            />
            <div class="mx-1 text-justify">
              <span class="headingH">{{ $t("SafeShopping") }}</span>
              <span class="content py-3">
                {{ $t("SafeShoppingDesc") }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-12 homeListItem">
          <div
            href="https://alyaman.com/product/%d8%a7%d9%84%d9%8a%d9%88%d9%85-%d8%a7%d9%84%d9%88%d8%b7%d9%86%d9%8a-%d8%a7%d9%84%d8%b3%d8%b9%d9%88%d8%af%d9%8a-%d9%85%d8%ac%d9%85%d9%88%d8%b9%d8%a9-%d8%b2%d9%8a%d9%86%d8%a9-%d8%a3%d8%b3%d9%82%d9%81-15/"
            class="d-flex"
          >
            <img
              src="@/assets/logo/0.png"
              class="HomeListLogo img-fluid"
              width="64"
              height="64"
              alt=""
            />
            <div class="mx-2 text-justify">
              <span class="headingH">{{ $t("CustSer") }}</span>
              <span class="content py-3">
                {{ $t("CustSerDesc") }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-12 homeListItem">
          <div
            href="https://alyaman.com/product/%d8%a7%d9%84%d9%8a%d9%88%d9%85-%d8%a7%d9%84%d9%88%d8%b7%d9%86%d9%8a-%d8%a7%d9%84%d8%b3%d8%b9%d9%88%d8%af%d9%8a-%d9%85%d8%ac%d9%85%d9%88%d8%b9%d8%a9-%d8%b2%d9%8a%d9%86%d8%a9-%d8%a3%d8%b3%d9%82%d9%81-15/"
            class="d-flex"
          >
            <img
              src="@/assets/logo/4.png"
              class="HomeListLogo img-fluid"
              width="64"
              height="64"
              alt=""
            />
            <div class="mx-2 text-justify">
              <span class="headingH">{{ $t("SpecialOrders") }}</span>
              <span class="content py-3">
                {{ $t("SpecialOrdersDesc") }}
                <br />
                <span>
                  <a
                    href="https://wa.me/971565628959?text= مرحباً، أريد الاستفسار عن منتجاتكم"
                    style="text-decoration: underline" dir="ltr"
                    >+971 56 562 8959</a
                  >.
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-12 homeListItem">
          <div
            href="https://alyaman.com/product/%d8%a7%d9%84%d9%8a%d9%88%d9%85-%d8%a7%d9%84%d9%88%d8%b7%d9%86%d9%8a-%d8%a7%d9%84%d8%b3%d8%b9%d9%88%d8%af%d9%8a-%d9%85%d8%ac%d9%85%d9%88%d8%b9%d8%a9-%d8%b2%d9%8a%d9%86%d8%a9-%d8%a3%d8%b3%d9%82%d9%81-15/"
            class="d-flex"
          >
            <img
              src="@/assets/logo/5.png"
              class="HomeListLogo img-fluid"
              width="64"
              height="64"
              alt=""
            />
            <div class="mx-2 text-justify">
              <span class="headingH">{{ $t("PayWay") }}</span>
              <span class="content py-3">
                {{ $t("PayWayDesc") }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="css" scoped>
.homeList {
  background-color: white;
  margin-top: 2px;
  margin-bottom: -10px;
  padding: 8px 0;
}

.HomeListLogo {
  margin-bottom: 10px;
}
.homeListItem {
  text-align: center;
  margin: 10px 0;
}

.homeListItem div img {
  width: 20%;
}

.content {
  display: block;
  color: #808080 !important;
  font-size: 12px;
}

.headingH {
  color: #fb660d;
  font-weight: 700;
  font-size: 20px;
}
</style> 

