<template>
  <b-container fluid>
    <b-skeleton-wrapper :loading="ProdByTagLoading">
      <template #loading>
        <b-container fluid>
          <landingLoader></landingLoader>
        </b-container>
      </template>

      <div
        v-if="!ProdByTagLoading && products.tag != null"
        class="bg-white my-4"
      >
        <b-row align-v="center" class="pt-2 px-0 flickity-card m-1">
          <b-col align="start" class="text-primary">
            <h6 v-if="$mq === 'sm'">
              {{ tagName }}
            </h6>
            <h4 v-else>
              {{ tagName }}
            </h4>
          </b-col>
          <b-col align="end">
            <b-button
              variant="outline-secondary"
              :to="{ name: 'ProdByTag', params: { ProdByTag: Tag } }"
             class="py-1 font-weight-bold"
              :style="[$mq == 'sm' ? 'font-size: 12px;' : 'font-size: 15px;']"
              >{{ $t("ShowMore") }}</b-button
            >
          </b-col>
        </b-row>

        <div class="container-fluid">
          <div v-if="products.tag != null" class="row">
            <div
              v-for="(Product, index) in products.tag"
              v-bind:key="Product.id"
              :class="
                count == '4'
                  ? `col-6 col-sm-3 GridItem grid-discount`
                  : `col-4 col-sm-3 GridItem grid-discount`
              "
            >
              <ProdGridBox
                v-if="index < count"
                v-bind:Product="Product"
              ></ProdGridBox>
            </div>
          </div>
        </div>
      </div>
    </b-skeleton-wrapper>
  </b-container>
</template>


<script>
import { mapActions } from "vuex";
import landingLoader from "./landingLoader.vue";
import ProdGridBox from "../items/ProdGridBox.vue";
import axios from "axios";
import cookie from 'vue-cookies';
export default {
  name: "GridTag",
  props: ["Tag", "tagName", "count"],
 
  components: {
    ProdGridBox,
    landingLoader,
  },
  methods: {
    ...mapActions(["getProdByTag"]),
  getResults( ) {
        var self=this;
        var CountryVal = cookie.get('shipCountry');
        var CurrVal = cookie.get('wmc_current_currency');
        let cur;
        let country

        if (CountryVal && CurrVal) {
            cur = CurrVal;
            country = CountryVal;
        } else {
            cur = 'SAR';
            country = 'SA';
        }
        var setUrl = process.env.VUE_APP_DEVBASEURL + '/ProdByTag/' + this.Tag + '/' + cur + '/' + country + '/' + this.$mq+ '/' + "Gird"+ '/' +"1000" + '/' +"0" + '/' + "5" + '/' + "0" ;

        axios.get(setUrl).then(function(response) {
       
            self.products = response.data
             
       

        })
    },
  },
  mounted() {
    this.getResults();
  },
  data() {
    return {
      ProdByTagLoading: true,
      products:[]

    };
  },
  watch: {
    products(newValue) {
      if (newValue) {
        this.ProdByTagLoading = false;
      }
    },
  },
};
</script>


<style scope>
.grid {
  display: inline;
}
</style>