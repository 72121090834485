<template>
  <b-container fluid class="bg-white mt-3 ">
    <b-row
      align-v="center"
      align-content="center"
      class="pt-2 px-0 text-primary m-3 text-center"
    >
      <h6 v-if="$mq === 'sm'" >المنتج الاكثر رواجاً</h6>
      <h4 v-else >المنتج الاكثر رواجاً</h4>
    </b-row>
    <b-row class="my-2 pb-5 mx-auto" align-content="center" align-h="center">
      <b-col cols="6" sm="3"  class="px-0">
        <router-link
            :to="{
              name: 'ProdOne',
              params: { ProdName: `لفة ورود قماشية`, Id: `23117` },
            }"
      
            >
          <img 
            class="img-fluid shadow rounded img-thumbnail"
            :src="require(`../../assets/banners/prod1.jpeg`)"
          />
        </router-link>
      </b-col>
         <b-col cols="6" sm="3" class="px-0">
         <router-link
            :to="{
              name: 'ProdOne',
              params: { ProdName: `لفة ورود قماشية مع لؤلؤ`, Id: `23138` },
            }">
          <img
            class="img-fluid shadow rounded img-thumbnail"
            :src="require(`../../assets/banners/prod2.jpeg`)"
          />
      </router-link>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "SpecialProduct",

}

</script>

<style>
</style>