<template>
  <b-container fluid>
    <b-skeleton-wrapper :loading="ProdByTagLoading">
      <template #loading>
        <b-container fluid>
          <ProdListLoader></ProdListLoader>
        </b-container>
      </template>

      <div
        v-if="!ProdByTagLoading && products.category != null"
        class="bg-white my-4 slide-h"
      >
        <b-row
          v-if="products.category.length > 0"
          align-v="center"
          class="pt-2 px-0 flickity-card m-1"
        >
          <b-col align="start" class="text-primary">
            <h6 v-if="$mq === 'sm'">
              {{ catName }}
            </h6>
            <h4 v-else>
              {{ catName }}
            </h4>
          </b-col>
          <b-col align="end">
            <b-button
              variant="outline-secondary"
              class="py-1 font-weight-bold"
              :style="[$mq == 'sm' ? 'font-size: 12px;' : 'font-size: 15px;']"
              :to="{ name: 'ProdByCat', params: { ProdByCat: Cat } }"
              >{{ $t("ShowMore") }}</b-button
            >
          </b-col>
        </b-row>
        <flickity
          :options="flickityOptions"
          v-if="products.category != null && $mq == 'sm'"
        >
          <ProdCard
            v-for="index in 10"
            v-bind:key="index"
            v-bind:Product="products.category[index - 1]"
            class="col-sm-2 col-8 px-1"
          ></ProdCard>
        </flickity>
        <flickity
          :options="flickityOptions2"
          v-if="products.category != null && $mq != 'sm'"
        >
          <ProdCard
            v-for="index in 10"
            v-bind:key="index"
            v-bind:Product="products.category[index - 1]"
            class="col-sm-2 col-8 px-1"
          ></ProdCard>
        </flickity>
      </div>
    </b-skeleton-wrapper>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import ProdCard from "../items/ProdCard.vue";
import Flickity from "vue-flickity";
import axios from "axios";
import cookie from "vue-cookies";
import ProdListLoader from "../widgets/PordListLoader.vue";

export default {
  name: "ProdCat",
  props: ["Cat", "catName"],
  computed: {
    ...mapGetters(["ProdByTagLayout"]),
  },
  components: {
    ProdCard,
    Flickity,
    ProdListLoader,
  },
  methods: {
    getResults() {
      var self = this;
      var CountryVal = cookie.get("shipCountry");
      var CurrVal = cookie.get("wmc_current_currency");
      let cur;
      let country;

      if (CountryVal && CurrVal) {
        cur = CurrVal;
        country = CountryVal;
      } else {
        cur = "SAR";
        country = "SA";
      }

      var setUrl =
        process.env.VUE_APP_DEVBASEURL +
        "/ProdByCat/" +
        this.Cat +
        "/" +
        cur +
        "/" +
        country +
        "/" +
        this.$mq +
        "/" +
        "New" +
        "/" +
        "1000" +
        "/" +
        "0" +
        "/" +
        "5" +
        "/" +
        "0";

      axios.get(setUrl).then(function(response) {
        self.products = response.data;
      });
    },
  },
  mounted() {
    this.getResults();
  },
  data() {
    return {
      ProdByTagLoading: true,
      flickityOptions: {
        rightToLeft: true,
        initialIndex: 2,
        pageDots: false,
        freeScroll: false,
        wrapAround: false,
        contain: true,
        prevNextButtons: false,
        imagesLoaded: true,
      },
      flickityOptions2: {
        rightToLeft: true,
        initialIndex: 2,
        pageDots: false,
        freeScroll: false,
        wrapAround: false,
        contain: true,
        prevNextButtons: true,
        imagesLoaded: true,
      },
      products: [],
    };
  },
  watch: {
    products(newValue) {
      if (newValue) {
        this.ProdByTagLoading = false;
      }
    },
  },
};
</script>

<style scope>
.slide-h {
  min-height: 387px !important;
}
</style>
