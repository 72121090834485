<template>
  <div class="shadow pb-2">
    <b-row
      v-if="isHome == 'Home'"
      align-v="center"
      class="py-2 px-0 flickity-card mx-1"
    >
      <b-col align="start" class="flickity-title text-primary">
        <h6 v-if="$mq === 'sm'">
          {{ $t("CustRates") }}
        </h6>
        <h4 v-else>
          {{ $t("CustRates") }}
        </h4>
      </b-col>
      <b-col align="end">
        <b-button
          variant="outline-secondary"
          class="py-1 font-weight-bold"
          :style="[$mq == 'sm' ? 'font-size: 12px;' : 'font-size: 15px;']"
          :to="{ name: 'AllRate', params: { param: 'All' } }"
          >{{ $t("ShowMore") }}</b-button
        >
      </b-col>
    </b-row>
    <Flickity ref="flickity" :options="flickityOptions2" v-if="$mq === 'lg'">
      <div
        v-for="item in rates.slice(0.8)"
        v-bind:key="item.id"
        class="  mx-2 text-right"
        style="width: 40%;"
      >
        <div v-if="isHome == 'Home'">
          <b-card>
            <div class="row ">
              <div class="col-1 mx-2 d-flex justify-content-start">
                <img
                  class=""
                  src="@/assets/userimage.png"
                  alt=""
                  width="50"
                  height="50"
                />
              </div>
              <div class="col-7 mx-1">
                <h6 class="text-bold">{{ item.RateName }}</h6>
                <star-rating
                  class="mb-2"
                  :rating="parseFloat(item.RateVal1)"
                  :increment="0.01"
                  :read-only="true"
                  :star-size="16"
                  :show-rating="false"
                  v-bind:rtl="true"
                  active-color="#fe6a00"
                  :glow="1"
                  :animate="true"
                ></star-rating>
              </div>
            </div>
            <div>
              <p class="text-center overflow-rate" :id="`rate-${item.id}`">
                {{ item.RateDesc }}
              </p>
              <b-tooltip :target="`rate-${item.id}`" variant="primary">
                {{ item.RateDesc }}
              </b-tooltip>
            </div>
          </b-card>
        </div>
        <div v-else>
          <b-card>
            <div class="row">
              <div class="col-2 d-flex justify-content-start">
                <img
                  class=""
                  src="@/assets/userimage.png"
                  alt=""
                  width="50"
                  height="50"
                />
              </div>
              <div class="col-7 mx-1">
                <h6 class="text-bold">{{ item.RateName }}</h6>
                <star-rating
                  class="mb-2"
                  :rating="parseFloat(item.RateVal1)"
                  :increment="0.01"
                  :read-only="true"
                  :star-size="16"
                  :show-rating="false"
                  v-bind:rtl="true"
                  active-color="#fe6a00"
                  :glow="1"
                  :animate="true"
                ></star-rating>
              </div>
            </div>
            <p class="text-center">{{ item.RateDesc.length }}</p>
          </b-card>
        </div>
      </div>
    </Flickity>
    <Flickity ref="flickity" :options="flickityOptions" v-else>
      <div
        class="w-100 mx-2 text-right"
        v-for="(item, index) in this.rates"
        v-bind:key="item.id"
      >
        <div v-if="isHome == 'Home'">
          <b-card v-if="index < 8">
            <div class="row">
              <div class="col-2 d-flex justify-content-start">
                <img
                  class=""
                  src="@/assets/userimage.png"
                  alt=""
                  width="50"
                  height="50"
                />
              </div>
              <div class="col-7 mx-2">
                <h6 class="text-bold">{{ item.RateName }}</h6>
                <star-rating
                  class="mb-2"
                  :rating="parseFloat(item.RateVal1)"
                  :increment="0.01"
                  :read-only="true"
                  :star-size="16"
                  :show-rating="false"
                  v-bind:rtl="true"
                  active-color="#fe6a00"
                  :glow="1"
                  :animate="true"
                ></star-rating>
              </div>
            </div>
            <p class="text-center">{{ item.RateDesc }}</p>
          </b-card>
        </div>
        <div v-else>
          <b-card>
            <div class="row">
              <div class="col-2 d-flex justify-content-start">
                <img
                  class=""
                  src="@/assets/userimage.png"
                  alt=""
                  width="50"
                  height="50"
                />
              </div>
              <div class="col-7 mx-2">
                <h6 class="text-bold">{{ item.RateName }}</h6>
                <star-rating
                  class="mb-2"
                  :rating="parseFloat(item.RateVal1)"
                  :increment="0.01"
                  :read-only="true"
                  :star-size="16"
                  :show-rating="false"
                  v-bind:rtl="true"
                  active-color="#fe6a00"
                  :glow="1"
                  :animate="true"
                ></star-rating>
              </div>
            </div>
            <p class="text-center">{{ item.RateDesc }}</p>
          </b-card>
        </div>
      </div>
    </Flickity>
  </div>
</template>

<script>
import Flickity from "vue-flickity";
import StarRating from "vue-star-rating";
export default {
  props: ["rates"],
  components: {
    StarRating,
    Flickity,
  },
  computed: {
    isHome() {
      return this.$route.name;
    },
  },
  data() {
    return {
      rateArr: [],
      flickityOptions: {
        initialIndex: 3,
        autoPlay: 4000,
        pageDots: false,
        freeScroll: true,
        wrapAround: false,
        prevNextButtons: false,
      },
      flickityOptions2: {
        initialIndex: 3,
        autoPlay: 4000,
        pageDots: false,
        wrapAround: false,
        contain: true,
        prevNextButtons: true,
        groupCells: true,
        freeScroll: true,
      },
    };
  },
};
</script>

<style scoped>
.flickity-title {
  font-weight: bold;
  font-size: 20px;
}

.overflow-rate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.rate-tooltip {
  background-color: #eb5b2c;
  color: #fff;
  width: max-content;
  font-weight: bold;
}
</style>
